import { getCookie } from '../utils/cookies'

export const isBusinessCustomer = () => {
  const user = getCookie('customerType')

  // Return true if cookie is not set at all e.g on local and staging
  // so that normal flow works on staging/local
  return user ? user[1] === 'business' : true
}

export default {
  isBusinessCustomer,
}
