/* eslint-disable import/no-cycle */ // TODO: Fix Resource - Product - Order import cycle and remove
import { RefObject } from 'react'

import { Locale } from '../../external'
import { AttributeProperty, ProductProperty } from './Resource'
import { CartProduct, OrderAttributes } from './Order'

export interface Order extends OrderAttributes {
  id: string
  'order/product': CartProduct[]
}

export type SourcingRowItem = {
  name: string
  sku: string
  url: string
  categoryPath: Pick<Catalog, 'catalog.config' | 'catalog.code' | 'catalog.label' | 'catalog.level'>[]
  referencePrice: number
  comparisonProductSku: string
  estimatedOrderSize?: number | string
  addedTimestamp: number
  id?: string
}

export type CurrencyId = 'EUR' | 'SEK'
export type CurrencySymbol = '€' | 'kr'

type StatusNames = 'public' | 'private' | 'archived'
export type Public = 1
export type Private = 0
export type Archived = -2
export type Status = Public | Private | Archived

export interface Catalog {
  catalog?: Catalog[]
  id: string
  'catalog.code': string
  'catalog.config': { referenceUnit: 'pair' | '100 pcs' } | []
  'catalog.hasChildren': boolean
  'catalog.parentid': string
  'catalog.label': string
  'catalog.status': number
  'catalog.target': string
  'catalog.url': string
  'text.long'?: string
  text: ProductText[]
  product?: Product[]
  'catalog.id'?: string
  'catalog.level'?: number
  'catalog.siteid'?: string
  'catalog.ctime'?: string
  'catalog.mtime'?: string
  'catalog.editor'?: string
  'catalog.lists.position'?: number
  attribute?: ProductAttribute[]
  media?: ProductMedia[]
}

export interface CategoryTreeCatalog {
  'catalog.code': string
  'catalog.label': string
  catalog: CategoryTreeCatalog[]
}

export type CompactTexts<TextTypes extends string = never> = {
  [key in `text.${TextTypes}`]?: string // Compact response
}

export type CompactStock = {
  'stock.dateback'?: string | null
  'stock.stocklevel'?: string | number | null
}

export type CompactFeeShipping = {
  'product.free-shipping'?: boolean
}

export type CompactSalesUnit = {
  'product.sales-unit'?: Exclude<SalesUnit, 'pair'>
  'product.referenceUnit'?: 'piece' | 'pair' | '100 pcs'
}

export type CompactHighlight = {
  'product.highlight'?: string
}

export interface Product extends CompactTexts<'name' | 'brand' | 'model' | 'short'>, CompactStock, CompactFeeShipping, CompactSalesUnit, CompactHighlight {
  attribute: ProductAttribute[]
  catalog: Catalog[]
  id: string
  media: ProductMedia[]
  price: ProductPrice[]
  variantIndex?: number
  'product.code': string
  'product.id': string
  'product.label': string
  'product.rating'?: string
  'product.ratings'?: number
  'product.scale': string
  'product.status': Status
  'product.target': string
  'product.type': string
  'product.url': string
  'product/property': ProductProperty[]
  stock: ProductStock[]
  supplier: Supplier[]
  text: ProductText[]
  product?: Product[]
  boughtTogether?: Product[]
  rfqProduct?: boolean
  'product.siteid'?: string
  'product.config'?: any[]
  'product.ctime'?: string
  'product.mtime'?: string
  'product.editor'?: string
  'product.dataset'?: string
  'product.dateend'?: string | null
  'product.datestart'?: string | null
  'product.lists.type'?: string // For bought together/variant products
  'product.lists.id'?: string
  'customer.lists.id'?: string
  'customer.lists.parentid'?: string
  'customer.lists.type'?: string
  'deletedVariantSkus'?: string[]
}

export interface PartialProduct extends Partial<Product> {
  'product.status': Status
  'product.code': string
  text: ProductText[]
  catalog: Catalog[]
  price: ProductPrice[]
  stock: ProductStock[]
  media: ProductMedia[]
  attribute: ProductAttribute[]
  product: Product[]
  rule?: Map<string, RuleReducerData>
  deletedVariantSkus?: string[]
}

export interface ProductText {
  id: string
  'text.type': 'name' | 'short' | 'long' | 'url' | 'brand' | 'model'
  'text.label': string
  'text.languageid': Locale | null
  'text.content': string
  'text.domain': 'product' | 'catalog' | 'attribute' | 'supplier'
  'text.id'?: string
  'text.siteid'?: string
  'text.ctime'?: string
  'text.mtime'?: string
  'text.editor'?: string
  'text.status'?: Status
}

export interface ProductPrice {
  id: string
  'price.type': string
  'price.label': string
  'price.currencyid': CurrencyId
  'price.domain': string
  'price.quantity': number
  'price.value': number | string
  'price.costs': number
  'price.taxrate': number
  'price.rebate'?: string
  'price.taxvalue'?: string
  'price.taxrates'?: Record<string, string>
  'price.taxflag'?: boolean
  'price.id'?: string
  'price.siteid'?: string
  'price.ctime'?: string
  'price.mtime'?: string
  'price.editor'?: string
  'price.status'?: Status
}

export interface ProductStock {
  id: string
  'stock.type': string
  'stock.productid': string
  'stock.stocklevel': number | null
  'stock.dateback': string | null
  'stock.timeframe'?: string
  'stock.id'?: string
  'stock.siteid'?: string
  'stock.ctime'?: string
  'stock.mtime'?: string
  'stock.editor'?: string
}

export interface ProductAttribute extends CompactTexts<'long'> {
  id: string
  'attribute.domain': string
  'attribute.type': string
  'attribute.code': string
  'attribute.label': string
  catalog?: Catalog[]
  text?: ProductText[] // TODO: Create AttributeText interface
  'attribute.status'?: Status
  'attribute.id'?: string
  'attribute.siteid'?: string
  'attribute.ctime'?: string
  'attribute.mtime'?: string
  'attribute.editor'?: string
  'attribute.position'?: number
  'attribute.key'?: string
  'product.lists.type'?: string
  'product.lists.id'?: string
  attribute?: ProductAttribute[]
  'attribute/property'?: AttributeProperty[]
  'attribute.property'?: Record<string, string>[]
  'text.long'?: string
}

export interface ProductMedia {
  id: string
  'media.type': string
  'media.languageid': Locale | null
  'media.domain': string
  'media.label': string
  'media.mimetype': string
  'media.preview': string
  'media.previews': Record<string, string>
  'media.url': string
  'media.id'?: string
  'media.siteid'?: string
  'media.ctime'?: string
  'media.mtime'?: string
  'media.editor'?: string
  'media.status'?: Status
  'product.lists.id'?: string // the relationship ID
  'product.lists.position'?: number // the position of the image
  data?: File
}

export interface DescriptiveFeatureType {
  code: string
  label: string
  value: string | string[]
  text: string | string[]
  type: 'descriptive'
}

export interface NumericFeatureType {
  code: string
  label: string
  value: string
  text: string
  type: 'numeric'
}

export type FeatureType = NumericFeatureType | DescriptiveFeatureType

export const isStatus = (status: number): status is Product['product.status'] => (
  status >= -2 && status <= 1
)

export const getStatus: Record<StatusNames, Public | Private | Archived> = {
  public: 1,
  private: 0,
  archived: -2,
}

export interface Service<T = 'delivery' | 'payment'> {
  id: string
  'service.type': T
  'service.code': string
  'service.label': string
  'service.provider': string
  'service.status': Status
  'service.config'?: Record<string, Record<'limit' | 'days' | 'mov', number>>
  'service.siteid'?: string
  price?: ProductPrice[]
}

export type DeliveryService = Service<'delivery'>
export type PaymentService = Service<'payment'>

export interface ServiceList {
  id: string
  'service.lists.id': string
  'service.lists.siteid': string
  'service.lists.ctime': string
  'service.lists.mtime': string
  'service.lists.editor': string
  'service.lists.domain': string
  'service.lists.refid': string
  'service.lists.datestart': string | null
  'service.lists.dateend': string | null
  'service.lists.config': string
  'service.lists.position': number
  'service.lists.status': Status
  'service.lists.type': string
  'service.lists.key': string
  'service.lists.parentid': string
}

export interface ProductList {
  id: string
  'product.lists.id': string
  'product.lists.siteid': string
  'product.lists.ctime': string
  'product.lists.mtime': string
  'product.lists.editor': string
  'product.lists.domain': string
  'product.lists.refid': string
  'product.lists.datestart': string | null
  'product.lists.dateend': string | null
  'product.lists.config': string
  'product.lists.position': number
  'product.lists.status': Status
  'product.lists.type': string
  'product.lists.key': string
  'product.lists.parentid': string
}
export interface CatalogList {
  id: string
  'catalog.lists.id': string
  'catalog.lists.siteid': string
  'catalog.lists.ctime': string
  'catalog.lists.mtime': string
  'catalog.lists.editor': string
  'catalog.lists.domain': string
  'catalog.lists.refid': string
  'catalog.lists.datestart': string | null
  'catalog.lists.dateend': string | null
  'catalog.lists.config': string
  'catalog.lists.position': number
  'catalog.lists.status': Status
  'catalog.lists.type': string
  'catalog.lists.key': string
  'catalog.lists.parentid': string
}
export interface Customer {
  id: string
  'customer.company': string
  'customer.firstname': string
  'customer.lastname': string
  'customer.address1': string
  'customer.address2': string
  'customer.address3': string
  'customer.birthday': string
  'customer.email': string
  'customer.city': string
  'customer.code': string
  'customer.countryid': string
  'customer.ctime': string
  'customer.dateverified': Date | null
  'customer.editor': string
  'customer.id': string
  'customer.label': string
  'customer.siteid': string
  'customer.status': Status
  'customer.admin': '0' | '1'
  'customer.superuser': boolean
  'customer.parentid': string
  product?: Partial<Product>[]
}

export interface CustomerRelationList {
  'customer.lists.id'?: string
  'customer.lists.domain': string
  'customer.lists.refid': string
  'customer.lists.datestart'?: null
  'customer.lists.dateend'?: null
  'customer.lists.config'?: { orderListId: string, quantity?: Record<string, number>, notes?: string, sourcingRowData?: SourcingRowItem }
  'customer.lists.position': number
  'customer.lists.status': number
  'customer.lists.type': string
  'customer.lists.parentid': string
  'customer.lists.editor': string
  id?: string
}

export interface CustomerPropertyList{
  'customer.property.id': string
  'customer.property.languageid'?: null
  'customer.property.value': string
  'customer.property.type': string
  'customer.property.parentid': string
  'customer.property.editor': string
  id: string
}

export interface Supplier extends CompactTexts<'long'> {
  'supplier.code': string
  text?: ProductText[]
  id: string
  product: Product[]
  service?: DeliveryService[]
  'supplier.id'?: string
  'supplier.siteid'?: string
  'supplier.label'?: string
  'supplier.status'?: Status
  'supplier.ctime'?: string
  'supplier.mtime'?: string
  'supplier.editor'?: string
  media?: ProductMedia[]
  // To fix type issue for supplier/address as admin response is not with "." but with "/"
  'supplier.address'?: SupplierAddress[] | Partial<SupplierAddress>[]
  'supplier/address'?: SupplierAddress[] | Partial<SupplierAddress>[]
  'text.long'?: string
  'service.config'?: Service['service.config']
  'service.price'?: Partial<Record<Uppercase<Locale>, {
    type: string
    label: string
    quantity: number
    value: string
    costs: number
    currencyid: CurrencyId
  }>>
}
export interface PartialSupplier extends Partial<Supplier> {
  'supplier.label': string
}
export interface SupplierList {
  id: string
  'supplier.lists.id': string
  'supplier.lists.siteid': string
  'supplier.lists.ctime': string
  'supplier.lists.mtime': string
  'supplier.lists.editor': string
  'supplier.lists.domain': string
  'supplier.lists.refid': string
  'supplier.lists.datestart': string | null
  'supplier.lists.dateend': string | null
  'supplier.lists.config': string
  'supplier.lists.position': number
  'supplier.lists.status': Status
  'supplier.lists.type': string
  'supplier.lists.key': string
  'supplier.lists.parentid': string
}
export interface SupplierMappedProductsProps extends Supplier {
  product: Product[]
}
export interface SupplierAddress {
  'id': string
  'supplier.address.id': string
  'supplier.address.siteid': string
  'supplier.address.ctime'?: string
  'supplier.address.mtime'?: string
  'supplier.address.editor'?: string
  'supplier.address.salutation': string
  'supplier.address.company': string
  'supplier.address.vatid': string
  'supplier.address.title': string
  'supplier.address.firstname': string
  'supplier.address.lastname': string
  'supplier.address.address1': string
  'supplier.address.address2': string
  'supplier.address.address3': string
  'supplier.address.postal': string
  'supplier.address.city': string
  'supplier.address.state': string
  'supplier.address.countryid': string
  'supplier.address.languageid': Locale
  'supplier.address.telephone': string
  'supplier.address.telefax': string
  'supplier.address.email': string
  'supplier.address.website': string
  'supplier.address.longitude': string | null
  'supplier.address.latitude': string | null
  'supplier.address.birthday': string | null
  'supplier.address.position': number
  'supplier.address.parentid': string
}

export interface Rule {
  id: string
  'rule.id': string
  'rule.label': string
  'rule.siteid': string
  'rule.type': 'catalog' | 'basket'
  'rule.provider': string
  'rule.config': string
  'rule.position': number
  'rule.status': Status
  'rule.mtime': string
  'rule.ctime': string
  'rule.editor': string
}

export interface RuleGql extends BaseGqlItem {
  id: string
  siteid: string
  type: string
  label: number
  provider: number
  config: string
  status: Status
}

export interface RuleConfig {
  id?: string
  sku: string
  buyer: string
  ruleConfig: string
}

interface RuleReducerData {
  id?: string
  ruleConfig: string
}

export interface RuleConfigData {
  key: string
  percent: string
  volume?: string
  buyer?: string
}

export type ResourceGql = ProductGql
| CatalogGql
| AttributeGql
| SupplierGql
| ProductStockGql
| RuleGql

export interface ProductPropertyGql {
  id: string
  type: string
  value: string
}
export interface ProductStockGql extends BaseGqlItem {
  id: string
  type: string
  productid: string
  stocklevel: string | null
  dateback: string | null
  timeframe?: string
}

export interface ListGql<T = Exclude<ResourceGql, ProductStockGql>> { // Stock does not use lists
  id: string
  item?: T
  type?: string
  position?: number
}

export interface BaseGqlItem {
  id: string
  lists?: ProductListsGql
  property?: ProductPropertyGql[]
}

export interface ProductListsGql {
  attribute?: ListGql<AttributeGql>[]
  catalog?: ListGql<CatalogGql>[]
  media?: ListGql<MediaGql>[]
  price?: ListGql<PriceGql>[]
  text?: ListGql<TextGql>[]
  product?: ListGql<ProductGql>[]
  supplier?: ListGql<SupplierGql>[]
}

export interface ProductGql extends BaseGqlItem {
  code: string
  id: string
  label: string
  type: string
  status: Status
  lists: ProductListsGql
  ctime?: string
  property?: ProductPropertyGql[]
  siteid?: string
  stock?: ProductStockGql[] // Since stock is not linked via lists, it doesn't have the list data
}
export interface CatalogGql extends BaseGqlItem {
  code: string
  id: string
  label: string
  level: number
  parentid: number
  url?: string
  status: Status
  lists?: ProductListsGql
  config?: string
}

export interface AttributeGql extends BaseGqlItem {
  code: string
  id: string
  label: string
  type: string
  status: Status
  lists?: ProductListsGql
  property?: ProductPropertyGql[]
}

export interface MediaGql extends BaseGqlItem {
  id: string
  label: string
  type: string
  url?: string
  status: Status
  previews?: string
  preview?: string
}

export interface PriceGql extends BaseGqlItem {
  id: string
  label: string
  type: string
  status: Status
  value: string
  costs: string
  quantity: number
  currencyid: string
  rebate: string
  taxrate: string
  taxrates: string
}

export interface TextGql {
  id: string
  label: string
  type: string
  status: Status
  content?: string
  languageid?: string
}

export interface SupplierGql extends BaseGqlItem{
  code: string
  id: string
  label: string
  siteid: string
}

export interface ProductQuantity {
  label: string
  quantity: number | string
  id: string
  manualStockLimit: number | null
  stockDate: string | null
  timeframe: string
  productId: string
  exactStockLevel?: number | null
  productCode?: string
  variantId?: string
  newVariant?: boolean
  price?: number
  discount?: number
}

export type WithAttributes<T> = T & { attribute: ProductAttribute[] }

export interface DiscountProduct extends ProductPrice {
  discountPercentage: number
  totalPiece: number
  referencePrice: number | string
}

export interface MediaType{
  original: string
  thumbnail: string
  originalHeight: number
  thumbnailHeight: number
  originalAlt: string
}

export enum PriceTag {
  Discount1 = 'bulk-discount-tier-1',
  Discount2 = 'bulk-discount-tier-2',
  Discount3 = 'bulk-discount-tier-3',
  Discount4 = 'bulk-discount-tier-4',
  Discount5 = 'bulk-discount-tier-5',
}

export type SortOptions = 'popular' | 'lowest' | 'highest' | 'newest' | 'name-az' | 'name-za' | 'quote-only' | ''

export type SalesUnit = 'carton' | 'package' | 'pair' | 'piece'

export type VariantTypeValue = 'color' | 'size' | undefined

export interface VariantType{
  value: VariantTypeValue
  label: string
}

export interface ProductNavigationRefs {
  productFeaturesRef: RefObject<HTMLDivElement>
  productStandardsRef: RefObject<HTMLDivElement>
  deliveryTermsRef: RefObject<HTMLDivElement>
  supplierProductsRef?: RefObject<HTMLDivElement>
  productDocumentsRef?: RefObject<HTMLDivElement>
}

export interface ResponseError {
  [x: string]: string[]
}

export type DiscountTag =
  'bulk-discount-tier-1'
  | 'bulk-discount-tier-2'
  | 'bulk-discount-tier-3'
  | 'bulk-discount-tier-4'
  | 'bulk-discount-tier-5'

export type ChangeSavedStatus = (value: boolean | 'hidden') => void

interface SupplierDataOrtto {
  supplierCode: string
  supplierName: string
}

export interface ProductDataOrtto {
  sku: string
  name: string
  image: string
  price: string
  productUrl: string
  supplier: SupplierDataOrtto
}

export interface ProductInfoOrtto {
  products: ProductDataOrtto[]
}
