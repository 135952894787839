import i18nConfig from '../i18n'
import { CountryAndLocale, Locale } from '../external'
import { ActiveCountryId, CountryId, VALID_LOCALE_COUNTRY, VALID_LOCALE_LIST } from './constants'
import { CurrencyId } from './types/Product'

interface CountriesAndLocalesObj {
  countries: ActiveCountryId[]
  locales: Locale[]
}

interface CountryAndLocaleObj {
  country: ActiveCountryId
  locale: Locale
}

const { defaultLocale: defaultCountryAndLocale } = i18nConfig

const [defaultCountry, defaultLocale] = defaultCountryAndLocale.split('-') as [Extract<Locale, 'fi'>, Extract<Locale, 'en'>]
const defaultCountryAndLocaleObj: CountryAndLocaleObj = {
  country: defaultCountry,
  locale: defaultLocale,
}

// Typeguard to guarantee the correct locale values
const isValidLocale = (
  localeStr: string,
): localeStr is Locale => Boolean(localeStr) && VALID_LOCALE_LIST.includes(localeStr as Locale)

// Typeguard to guarantee the correct country values
const isValidCountry = (
  localeStr: string,
): localeStr is ActiveCountryId => Boolean(localeStr)
  && Boolean(VALID_LOCALE_COUNTRY[localeStr as ActiveCountryId])

/**
 * Take 2 string values that represent a country ID and a locale ID and concat them together
 * to make a valid country and locale string.
 * @example generateCountryAndLocaleString('fi', 'en') -> 'fi-en'
 */
export const generateCountryAndLocaleString = (
  country: Locale,
  locale: Locale,
) => {
  if (
    isValidCountry(country.toLocaleLowerCase())
    && isValidLocale(locale.toLocaleLowerCase())
  ) {
    return `${country}-${locale}`.toLowerCase() as CountryAndLocale
  }
  console.error(`Tried passing invalid locale params - country:${country}, locale:${locale}`)
  return `${defaultCountry}-${defaultLocale}` as CountryAndLocale
}

/**
 * Take a country and locale string, i.e. fi-fi, de-en, etc, and destructure it to have the country
 * and locale as separate strings.
 * @example getCountryAndLocaleStrings('de-en') -> {country: 'de', locale: 'en'}
 */
export const getCountryAndLocaleStrings = (
  localeString: string | CountryAndLocale = defaultCountryAndLocale,
): CountryAndLocaleObj => {
  // If for some reason it's not the standard 'xx-yy' string format
  if (!localeString.includes('-')) {
    if (isValidLocale(localeString)) {
      return {
        country: localeString === 'en' ? defaultCountry : localeString,
        locale: localeString,
      }
    }
    return defaultCountryAndLocaleObj
  }

  const [country, locale] = localeString.split('-').map((str) => str.toLocaleLowerCase())

  if (!isValidCountry(country) || !isValidLocale(locale)) {
    console.error(`Either country or locale values are invalid - country:${country}, locale:${locale}`)
    return defaultCountryAndLocaleObj
  }

  return { country, locale }
}

/**
 * Takes an array of country-locale strings and breaks them down to 2 separate arrays of countries
 * and locales.
 * @example getCountryAndLocalesLists(['fi-fi', 'fi-sv', 'sv-en']) ->
 * {countries: ['fi', 'sv'], locales: ['fi', 'sv', 'en']}
 */
export const getCountryAndLocalesLists = (
  localeList: string[],
) => localeList.reduce<CountriesAndLocalesObj>(
  (acc, curr) => {
    let countries = [...acc.countries]
    let locales = [...acc.locales]

    const { country, locale } = getCountryAndLocaleStrings(curr)

    if (!countries.includes(country)) {
      countries = [...countries, country]
    }
    if (!locales.includes(locale)) {
      locales = [...locales, locale]
    }

    return {
      countries,
      locales,
    }
  }, {
    locales: [],
    countries: [],
  },
)

export const getCurrencyCode = (country: CountryId): CurrencyId => {
  if (country === 'sv') {
    return 'SEK'
  }
  return 'EUR'
}

export const getCurrencyFromCountryAndLocale = (
  countryAndLocale: CountryAndLocale,
): CurrencyId => {
  const { country } = getCountryAndLocaleStrings(countryAndLocale)
  return getCurrencyCode(country)
}
