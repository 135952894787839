import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { useCart } from '../../components/Cart/CartProvider'
import addContactInfo from '../../utils/analytics/addContactInfo'
import addPaymentInfo from '../../utils/analytics/addPaymentInfo'
import addShippingInfo from '../../utils/analytics/addShippingInfo'
import beginCheckout from '../../utils/analytics/beginCheckout'
import purchase from '../../utils/analytics/purchase'
import removeFromCart from '../../utils/analytics/removeFromCart'
import viewCart from '../../utils/analytics/viewCart'
import viewDetailsConfirmation from '../../utils/analytics/viewDetailsConfirmation'
import viewItem from '../../utils/analytics/viewItem'
import { Product } from '../../utils/types/Product'
import { CartResponse, OrderProductInclude, WithSuppliers } from '../../utils/types/Order'
import { isProduction } from '../../utils/environment'
import { isSuperUser } from '../../lib/supplier'
import { useAuth } from '../useAuth'
import updateCart from '../../utils/analytics/updateCart'
import { getCountryAndLocaleStrings } from '../../utils/locales'
import { AddressType, AddToCartEventParams } from '../../utils/types/analytics'
import useTakeRate from '../useTakeRate'
import { generateShareCartUrl } from '../../lib/cart'
import { CountryAndLocale } from '../../external'
import addToCart from '../../utils/analytics/addToCart'
import useUrls from '../useUrls'
import useGetLocalizedProductUrl from '../../components/SingleProduct/hooks/useGetLocalizedProductUrl'
import addAddress from '../../utils/analytics/addAddress'
import useGetAnalyticsPageLocation from './useGetAnalyticsPageLocation'
import addOrderReference from '../../utils/analytics/addOrderReference'
import beginChangeAddress from '../../utils/analytics/beginChangeAddress'
import changeAddress from '../../utils/analytics/changeAddress'
import editAddress from '../../utils/analytics/editAddress'
import triggerCheckoutAbandonmentFlow from '../../utils/analytics/triggerCheckoutAbandonmentFlow'
import useLocalStorageViewedProducts from '../../components/hooks/useLocalStorageViewedProducts'

const useEcomEvents = () => {
  const { cartState: { cart: cartData } } = useCart()
  const { user, isImpersonated } = useAuth()
  const { locale: countryAndLangString } = useRouter()
  const { country } = getCountryAndLocaleStrings(countryAndLangString)
  const calculateTakeRate = useTakeRate()
  const { urlT } = useUrls()
  const { t } = useTranslation('common')
  const analyticsLocation = useGetAnalyticsPageLocation()
  const { addViewedSku } = useLocalStorageViewedProducts()

  const getLocalizedProductUrl = useGetLocalizedProductUrl()

  const addAddressEvent = (type: AddressType) => {
    const addressType = type === 'delivery' ? 'delivery_address' : 'billing_address'
    addAddress(analyticsLocation ? `${analyticsLocation}_${addressType}` : addressType)
  }

  const addOrderReferenceEvent = (orderReference: string) => {
    addOrderReference(orderReference, analyticsLocation)
  }

  const beginChangeAddressEvent = (type: AddressType) => {
    const addressType = type === 'delivery' ? 'delivery_address' : 'billing_address'
    beginChangeAddress(analyticsLocation ? `${analyticsLocation}_${addressType}` : addressType)
  }

  const changeAddressEvent = (type: AddressType) => {
    const addressType = type === 'delivery' ? 'delivery_address' : 'billing_address'
    changeAddress(analyticsLocation ? `${analyticsLocation}_${addressType}` : addressType)
  }

  const editAddressEvent = (type: AddressType) => {
    const addressType = type === 'delivery' ? 'delivery_address' : 'billing_address'
    editAddress(analyticsLocation ? `${analyticsLocation}_${addressType}` : addressType)
  }

  const viewItemEvent = (product: Product) => {
    const { product: productWithTakerate } = calculateTakeRate(product, 0)

    // Add viewed item to local storage
    addViewedSku(product)

    viewItem({
      product: productWithTakerate,
      country,
      getLocalizedProductUrl,
    })
  }

  const addToCartEvent = ({
    productsData,
    context,
    catalogName,
    modifiedCart,
  }: AddToCartEventParams) => {
    addToCart({
      calculateTakeRate,
      modifiedCart,
      productsData,
      context,
      catalogName,
      country,
      t,
      shareCartUrl: modifiedCart ? generateShareCartUrl(modifiedCart, countryAndLangString as CountryAndLocale, urlT) : '',
      getLocalizedProductUrl,
    })
  }

  // Used for faulty add to cart events where product amount is under MOQ
  const addToCartUnderMOQEvent = ({
    productsData,
  }: AddToCartEventParams) => {
    addToCart({
      calculateTakeRate,
      productsData,
      isUnderMOQ: true,
      country,
      t,
      shareCartUrl: cartData ? generateShareCartUrl(cartData, countryAndLangString as CountryAndLocale, urlT) : '',
      getLocalizedProductUrl,
    })
  }

  const viewCartEvent = async (context?: 'modify_reorder') => {
    if (cartData) {
      viewCart({
        cartData,
        context,
        country,
        getLocalizedProductUrl,
      })
    }
  }

  const removeFromCartEvent = (basketProducts?: Array<OrderProductInclude | undefined>) => {
    if (cartData) {
      removeFromCart({
        basketProducts,
        cartData,
        getLocalizedProductUrl,
      })
    }
  }

  // Below events only used in checkout flow:
  const beginCheckoutEvent = (context?: 'quick_reorder', newCartData?: any) => {
    const cData = newCartData || cartData

    if (cData) {
      beginCheckout({
        cartData: cData,
        context,
        getLocalizedProductUrl,
      })
    }
  }

  const triggerCheckoutAbandonmentFlowEvent = (email: string, isGuest: boolean) => {
    if (cartData) {
      triggerCheckoutAbandonmentFlow({
        email,
        cartData,
        country,
        getLocalizedProductUrl,
        isGuest,
      })
    }
  }

  const addContactInfoEvent = (email: string) => {
    if (cartData) {
      addContactInfo({
        email,
        isCheckout: true,
        cartData,
        country,
        context: analyticsLocation,
        getLocalizedProductUrl,
      })
    }
  }

  const addShippingInfoEvent = (isMatchingAddresses: boolean) => {
    if (cartData) {
      addShippingInfo({
        cartData,
        getLocalizedProductUrl,
        isMatchingAddresses,
        context: analyticsLocation,
      })
    }
  }

  const addPaymentInfoEvent = (paymentType: string) => {
    if (cartData) {
      addPaymentInfo({
        cartData,
        paymentType,
        getLocalizedProductUrl,
      })
    }
  }

  const viewDetailsConfirmationEvent = () => {
    if (cartData) {
      viewDetailsConfirmation({
        cartData,
        getLocalizedProductUrl,
      })
    }
  }

  const purchaseEvent = (
    orderData: WithSuppliers<CartResponse>,
    orderId: string,
    isFirstPurchase?: boolean,
  ) => {
    const sendToProductionAnalytics = isProduction && (isSuperUser(user) || isImpersonated)

      // eslint-disable-next-line no-alert
      ? window.confirm("Are you ordering for a customer? If yes click 'OK', otherwise click 'CANCEL'. This choice will only affect analytics.")
      : false

    purchase({
      orderData,
      orderId,
      sendToProductionAnalytics,
      isFirstPurchase,
      getLocalizedProductUrl,
    })
  }

  return {
    viewItemEvent,
    addToCartEvent,
    addToCartUnderMOQEvent,
    viewCartEvent,
    removeFromCartEvent,
    beginCheckoutEvent,
    addContactInfoEvent,
    addShippingInfoEvent,
    addPaymentInfoEvent,
    viewDetailsConfirmationEvent,
    purchaseEvent,
    updateCartEvent: updateCart,
    addAddressEvent,
    addOrderReferenceEvent,
    beginChangeAddressEvent,
    changeAddressEvent,
    editAddressEvent,
    triggerCheckoutAbandonmentFlowEvent,
  }
}

export default useEcomEvents
